<template>
<div class="container markdown-view">
  <Markdown v-if="contentKey" :content="$t(contentKey)" />
</div>
</template>

<script>
import Markdown from '@/components/widget/Markdown.vue';

export default {
  name: 'markdown-view',
  components: {
    Markdown,
  },
  props: {
    contentKey: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.markdown-view {
  box-sizing: border-box;
  padding-top: 64px;
  padding-bottom: 72px;

  p {
    @include p_small;
    margin: 12px 0 15px;
  }
  h2 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  li {
    list-style-type: disc;
    margin-left: 24px;
  }
  h3 {
    font-size: 20px;
    margin-top: 24px;
  }

  a {
    @include button_small_text;
    color: $main-blue;
  }
}
</style>
